<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <label
    class="relative"
    :for="id"
    :class="props.containerClass"
  >
    <slot
      name="label"
      v-bind="{ label, id }"
    >
      <span
        :class="{
          'sr-only': !labelVisible,
          '!mb-2 text-[0.75rem] text-blue-50 md:mb-0':
            props.variant === 'address',
        }"
      >{{ label }}</span>
    </slot>
    <v-select
      v-bind="$attrs"
      v-model="selectValue"
      :class="{
        'v-select--small': props.small,
        'v-select--search-inside-list': props.searchInsideList,
        'v-select--address': props.variant === 'address',
        'v-select--transparent': props.transparent,
        'v-select--dropdown-static': props.dropdownStatic,
        'v-select--detached': props.detachedDropdown,
        'v-select--role': props.variant === 'role',
        'v-select--error': Array.isArray(props.errors) && props.errors.length,
        'pointer-events-none': !selectInitialised,
      }"
      data-el="ui-select"
      :deselect-from-dropdown="true"
      :close-on-select="shouldCloseOnSelect"
      :multiple="props.multiple"
      :disabled="props.disabled"
      :clearable="props.clearable"
      :append-to-body="props.detachedDropdown"
      :calculate-position="calculateDetachedDropdown"
      :placeholder="props.placeholder"
      :searchable="props.searchable || props.searchInsideList"
      :uid="props.id"
      @search:blur="onBlur"
      @option:deselected="onOptionDeselected"
    >
      <template #open-indicator="{ attributes }">
        <UiIcon
          :name="props.icon?.name"
          :width="props.icon?.width ?? 10"
          :height="props.icon?.height ?? 9"
          v-bind="attributes"
        />
      </template>
      <template #option="{ label: optionLabel, value = null }">
        <div
          v-if="props.multiple"
          class="flex items-center gap-3"
        >
          <UiCheckbox
            :model-value="isSelectionCheckboxChecked(value)"
            variant="primary"
          />
          <span :class="[{ 'whitespace-pre-wrap': !props.detachedDropdown }]">{{
            optionLabel
          }}</span>
        </div>
        <div
          v-else-if="isExternalLink(value?.url)"
          class="relative flex gap-1 border-primary before:absolute before:-left-2.5 before:-top-2.5 before:w-[calc(100%+20px)] before:border-t before:border-t-blue-100"
        >
          <span
            :class="[{ 'whitespace-pre-wrap': !props.detachedDropdown }]"
          >{{ optionLabel }}</span>
          <UiIcon
            width="14px"
            height="14px"
            name="external-link"
          />
        </div>
        <span
          v-if="!props.multiple && !props.radioOption && !isExternalLink(value?.url)"
          :class="[{ 'whitespace-pre-wrap': !props.detachedDropdown }]"
        >
          {{ optionLabel }}
        </span>
        <div
          v-if="!props.multiple && props.radioOption && !isExternalLink(value?.url)"
          class="flex items-center gap-3"
        >
          <UiFormFieldRadio
            :id="value"
            :value="value"
            :selected-value="isSelectionRadioSelected(value)"
            variant="primary"
            name="selectOption"
          />
          <span :class="[{ 'whitespace-pre-wrap': !props.detachedDropdown }]">{{
            optionLabel
          }}</span>
        </div>
      </template>

      <template
        v-if="props.searchInsideList"
        #search="{ attributes, events }"
      >
        <UiIcon
          class="vs__search-icon absolute bottom-3 left-7 mb-0.5 hidden text-grey"
          width="12"
          height="12"
          name="search"
        />
        <input
          class="vs__search"
          v-bind="attributes"
          :placeholder="props.searchPlaceholder"
          v-on="events"
        >
      </template>

      <template
        #selected-option-container="{
          option: selectOption,
          deselect,
          disabled: optionDisabled,
        }"
      >
        <div class="flex items-center gap-1">
          <span
            class="line-clamp-1 whitespace-normal text-primary"
            :class="{ 'text-base font-bold': props.variant === 'role' }"
          >
            {{ selectOption.label }}
          </span>
          <!-- eslint-disable tailwindcss/no-custom-classname -->
          <button
            v-if="props.clearable && !props.multiple"
            type="button"
            :disabled="optionDisabled"
            class="vs__deselect"
            :title="`Deselect ${selectOption.label}`"
            :aria-label="`Deselect ${selectOption.label}`"
            @click.stop="deselect(selectOption)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
            >
              <path
                d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
              />
            </svg>
          </button>
          <!-- eslint-enable tailwindcss/no-custom-classname -->
        </div>
      </template>

      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      ><slot
        :name="name"
        v-bind="slotData"
      /></template>
    </v-select>
  </label>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import vSelect from 'vue-select'
import {
  autoUpdate,
  computePosition,
  flip,
  limitShift,
  offset,
  shift,
  size,
} from '@floating-ui/dom'
import UiFormFieldRadio from '../UiForm/UiFormFieldRadio/UiFormFieldRadio.vue'
import isExternalLink from '../../utils/isExternalLink'
import UiCheckbox from '../UiForm/UiCheckbox/UiCheckbox.vue'
import 'vue-select/dist/vue-select.css'
import '../../styles/vendor/vue-select-overrides.css'
import UiIcon from '../UiIcon/UiIcon.vue'
import type { UiSelectProps } from './UiSelect.types'
import { isObject } from './UiSelect.utils'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<UiSelectProps>(), {
  id: undefined,
  label: '',
  small: false,
  transparent: false,
  multiple: false,
  radioOption: false,
  clearable: false,
  disabled: false,
  labelVisible: false,
  containerClass: () => [],
  placeholder: 'Wybierz',
  searchPlaceholder: 'Wybierz',
  searchable: false,
  dropdownStatic: false,
  closeOnSelect: true,
  detachedDropdown: false,
  searchInsideList: false,
  variant: 'default',
  fullHeightDropdown: false,
  icon: {
    name: 'solid-arrow-down',
    width: 10,
    height: 9,
  },
  errors: () => [],
})

const emit = defineEmits<{
  (e: 'update:selected', value: any): void
  (e: 'search:blur', value: any): void
  (e: 'option:deselecting', value: any): void
}>()

const DEFAULT_DROPDOWN_POSITION = 'bottom-start'

const selectValue = computed({
  get: () => props.selected,
  set: value => emit('update:selected', value),
})

const shouldCloseOnSelect = computed(() => {
  if (props.multiple) {
    return false
  }

  return props.closeOnSelect
})

// purpose: fixes issue when user click on select before hydration - after this, select is not working (probably vue-select bug)
const selectInitialised = ref(false)
onMounted(() => {
  selectInitialised.value = true
})

function isSelectionCheckboxChecked(value: string) {
  if (!Array.isArray(props.selected)) {
    return
  }

  return props.selected.some(item => item.value === value)
}

function isSelectionRadioSelected(value: string) {
  if (isObject(props.selected) && props.selected && 'value' in props.selected) {
    return props.selected.value
  }
  return value
}

function calculateDetachedDropdown(dropdownList: HTMLElement, component: { $refs: any, $el: HTMLElement }) {
  dropdownList.classList.add('vs__dropdown-menu--detached')

  const cleanup = autoUpdate(component.$refs.toggle, dropdownList, () => {
    computePosition(component.$refs.toggle, dropdownList, {
      placement: DEFAULT_DROPDOWN_POSITION,
      middleware: [
        flip(),
        shift({ limiter: limitShift() }),
        offset(10),
        size({
          apply({ elements }) {
            const referenceWidth = elements.reference.offsetWidth
            Object.assign(elements.floating.style, {
              minWidth: `${referenceWidth}px`,
              width: 'max-content',
              zIndex: '100',
              maxHeight: props.fullHeightDropdown ? 'fit-content' : undefined,
            })
          },
        }),
      ],
    }).then(({ x, y }) => {
      Object.assign(dropdownList.style, {
        left: `${x}px`,
        top: `${y}px`,
      })
    })
  })

  return () => cleanup()
}

function onBlur() {
  emit('search:blur', props.selected)
}

function onOptionDeselected(value: any) {
  emit('option:deselecting', value)
}
</script>
